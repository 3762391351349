<template>
  <div id="downloads">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.download') }}</h2>
          <p></p>
        </div>
        <div class="meta_box">
          <div class="box">
            <strong>{{ $t('downloads.mt4') }}</strong>
            <p>{{ $t('downloads.mt4Desc') }}</p>
            <ul>
              <li>
                <a :href="urls.mt4.Windows" data-testid="windows4">
                  <div class="picture windows"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.windows') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a :href="urls.mt4.MacOS" data-testid="mac4">
                  <div class="picture iphone"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.mac') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a :href="urls.mt4.Android" data-testid="android4">
                  <div class="picture android"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.android') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a :href="urls.mt4.iOS" data-testid="ios4">
                  <div class="picture iphone"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.ios') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="box">
            <strong>{{ $t('downloads.mt5') }}</strong>
            <p>{{ $t('downloads.mt5Desc') }}</p>
            <ul>
              <li>
                <a :href="urls.mt5.Windows" data-testid="windows5">
                  <div class="picture windows"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.windows') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a :href="urls.mt5.MacOS" data-testid="mac5">
                  <div class="picture iphone"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.mac') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a :href="urls.mt5.Android" data-testid="android5">
                  <div class="picture android"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.android') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a :href="urls.mt5.iOS" data-testid="ios5">
                  <div class="picture iphone"></div>
                  <div class="bottom">
                    <p>{{ $t('downloads.ios') }}</p>
                    <div class="right">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <vDialog :visible.sync="dialogShow" :visibleFooter="false">
        <div class="download_content">
          <img
            src="@/assets/images/close_white.png"
            class="closeImg"
            alt
            @click="dialogShow = false"
            data-testid="closeImg"
          />
          <img src="@/assets/images/downloads/download.jpg" class="download_img" alt="app" />
          <div class="btn">
            <a href="https://play.google.com/store/apps/details?id=cn.com.vtmarkets">
              <img src="@/assets/images/banner/google_play.png" alt="Google Play" />
            </a>
            <a href="https://apps.apple.com/au/app/vt-markets-gold-trading/id1372647263">
              <img src="@/assets/images/banner/apple_store.png" alt="App Store" />
            </a>
          </div>
        </div>
      </vDialog> -->
    </div>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';
export default {
  components: { vDialog },
  data() {
    return {
      urls: this.$config.getMetaTraderLinks(this.$store.state.common.regulator),
      dialogShow: true
    };
  },
  mounted() {
    this.dialogShow = true;
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/downloads.scss';
</style>
